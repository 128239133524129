import { storeToRefs } from 'pinia'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useCatalogStore } from '@ecom/stores/catalog'
import { useSort } from '@ecom/composables/useSort/useSort'
import { useQuery } from '@ecom/composables/useQuery/useQuery'
import { useFilters } from '@ecom/composables/useFilters/useFilters'
import {
  DefaultPageSize,
  PageSizeOptions,
} from '@ecom/composables/usePageSize/usePageSize'
import type { CategoryCmsBlock } from '@ecom/composables/useCategoryBanner/useCategoryBanner.types'
import { parseCmsBlockContent } from '../helpers/cmsBlocks'
import type { CatalogProductsQuery, CatalogProductsQueryVariables, CategoryTree } from '#gql'
import { callWithNuxt } from '#app'

export enum CatalogOriginEnum {
  CATEGORY = 'category',
  SEARCH = 'search',
}

export type CatalogOrigin = `${CatalogOriginEnum}`

/**
 * @param origin - used in certain conditions, depending on wheter we use
 * composable in 'category', 'search', etc
 */
function useCatalog(origin: CatalogOrigin = CatalogOriginEnum.CATEGORY) {
  const app = useNuxtApp()
  const { $i18n, callHook } = app
  const { t } = $i18n
  const catalogStore = useCatalogStore()
  const router = useRouter()
  const { getParamsFromURL, changeFilters } = useQuery()
  const { createProductAttributeSortInput } = useSort()
  const {
    createProductAttributeFilterInput,
    getSelectedFiltersFromUrl,
    getClearableFilters,
  } = useFilters()
  const { categoryLink } = useAppNav()
  const {
    categoryError,
    catalogProductsError,
    isCategoryPending,
    isCatalogProductsPending,
    isCategoryWithProductsPending,
    isCategoryListPending,
    category,
    catalogProducts,
    categoryList,
    categoryBreadcrumbs,
    params,
    selectedFilters,
    clearableFilters,
    allFiltersVisible,
  } = storeToRefs(catalogStore)
  const {
    getCategory,
    getCatalogProducts,
    getCatalogAggregations,
    getCategoryList,
    setBreadcrumbs,
    setParams,
    toggleVisibleFiltersCount,
    setSelectedFilters,
    setClearableFilters: _setClearableFilters,
  } = catalogStore

  const pageSize = ref(DefaultPageSize)
  const routeQuery = computed(() => router.currentRoute.value.query)
  const routeParams = computed(() => router.currentRoute.value.params)
  const routePath = computed(() => {
    if (Array.isArray(routeParams.value.slug)) {
      return routeParams.value.slug?.join('/')
    }
    return undefined
  })

  const isAnyProductAvailable = computed((): boolean => {
    return Boolean(catalogProducts.value?.items?.length)
  })

  const areFiltersReady = computed((): boolean => {
    if (origin === CatalogOriginEnum.CATEGORY) {
      return Boolean(
        category.value?.uid
        && catalogProducts.value?.aggregations
        && catalogProducts.value?.aggregations?.length,
      )
    }
    return Boolean(
      catalogProducts.value?.aggregations
      && catalogProducts.value?.aggregations?.length,
    )
  })

  const areSortOptionsReady = computed((): boolean => {
    const isAnySortOptionAvailable = Boolean(
      Object.keys(catalogProducts.value?.sort_fields ?? {}).length,
    )
    if (origin === CatalogOriginEnum.CATEGORY) {
      return Boolean(category.value?.uid) && isAnySortOptionAvailable
    }
    return isAnySortOptionAvailable
  })

  const cmsBlock = computed((): CategoryCmsBlock | null =>
    parseCmsBlockContent(category?.value?.cms_block?.content ?? ''),
  )

  const setClearableFilters = () => {
    _setClearableFilters(
      getClearableFilters(
        catalogProducts.value?.aggregations ?? [],
        selectedFilters.value,
      ),
    )
  }

  const applyFilters = () => {
    changeFilters(selectedFilters.value)
    setClearableFilters()
  }

  /**
   * Pagination info
   */
  const pageInfo = computed(
    (): {
      current_page?: number | null
      page_size?: number | null
      total_pages?: number | null
    } => {
      return (
        catalogProducts.value?.page_info || {
          total_pages: 0,
        }
      )
    },
  )

  /**
   * Run initial category call for SSR or Client
   */
  const initCategory = async () => {
    if (
      import.meta.server
        || (import.meta.client
        && ((!category.value && !catalogProducts.value)
        || params.value.url !== routePath.value))
    ) {
      // Wrap with useAsyncData to prevent hydration mismatches -https://pinia.vuejs.org/ssr/nuxt.html#Awaiting-for-actions-in-pages
      await useAsyncData('initCategory', () => Promise.all([onRouteChange(), getCategoryList()]))
    }
  }

  /**
   * Reset all state
   */
  const resetCatalog = () => catalogStore.$reset()

  const onCategoryClick = async (category: CategoryTree) => {
    await navigateTo(categoryLink(category?.canonical_url ?? ''))
  }

  const getAggregations = async (parameters: CatalogProductsQueryVariables) => {
    const searchParameters = {
      search: decodeURIComponent(parameters.search ?? ''),
      filters: createProductAttributeFilterInput(parameters),
      pageSize: parameters.pageSize || DefaultPageSize,
      currentPage: parameters?.page || 1,
      sort: createProductAttributeSortInput(parameters.sort || 'relevance'),
    }

    return await getCatalogAggregations(searchParameters)
  }

  /**
   * Get products (based on a category uid, if 'origin === CatalogOriginEnum.CATEGORY' argument provided
   * to 'useCatalog' composable is set to true)
   * @param parameters
   */
  const getProducts = async (parameters: CatalogProductsQueryVariables) => {
    if (origin === CatalogOriginEnum.CATEGORY && !category.value) {
      catalogProducts.value = null
      return null
    }

    const paramsHolder = {
      ...parameters,
      ...(origin === CatalogOriginEnum.CATEGORY && { category_uid: category.value?.uid ?? [] }),
    }

    const searchParameters = {
      search: decodeURIComponent(paramsHolder.search ?? ''),
      filters: createProductAttributeFilterInput(paramsHolder),
      pageSize: paramsHolder.pageSize || DefaultPageSize,
      currentPage: paramsHolder?.page || 1,
      sort: createProductAttributeSortInput(paramsHolder.sort || 'relevance'),
    }

    const {
      filters,
      pageSize,
      currentPage: page,
      sort,
      search,
    } = searchParameters

    setParams({
      search,
      filters,
      pageSize,
      page,
      sort,
    })

    setSelectedFilters(getSelectedFiltersFromUrl())
    let response: CatalogProductsQuery['products'] | null
    await callWithNuxt(app, async () => {
      try {
        response = await getCatalogProducts({
          ...searchParameters,
          attributes: params.value.attributes,
          enableAggregations: paramsHolder?.enableAggregations ?? true,
        })
      }
      catch (error) {
        console.error(error)
        throw createError({
          statusCode: 404,
          statusMessage: t('we_couldnt_find_the_page_you_are_looking_for'),
          fatal: true,
        })
      }
    })
    setClearableFilters()
    return response
  }

  /**
   * Callback function for route change on category.vue
   */
  const onRouteChange = async () => {
    const currentSlug = router.currentRoute.value.params.slug
    setBreadcrumbs(currentSlug)
    setParams({
      url: routePath.value,
    })
    await fetchCategoryData()
  }

  /**
   * Callback function called whenever query changes
   */
  const fetchCategoryData = async () => {
    await getCategory()
    await getProducts({
      ...getParamsFromURL(),
    })
    await callHook('ecom:page:loading:end', {
      route: router.currentRoute.value,
    })
  }

  return {
    routeParams,
    routeQuery,
    routePath,
    pageSize,
    setParams,
    initCategory,
    resetCatalog,
    getCategory,
    getCategoryList,
    onCategoryClick,
    getProducts,
    getAggregations,
    onRouteChange,
    fetchCategoryData,
    categoryError,
    catalogProductsError,
    isCategoryPending,
    isCatalogProductsPending,
    isCategoryWithProductsPending,
    isCategoryListPending,
    category,
    catalogProducts,
    categoryList,
    categoryBreadcrumbs,
    params,
    allFiltersVisible,
    toggleVisibleFiltersCount,
    PageSizeOptions,
    pageInfo,
    isAnyProductAvailable,
    applyFilters,
    areFiltersReady,
    areSortOptionsReady,
    clearableFilters,
    setClearableFilters,
    cmsBlock,
  }
}

export { useCatalog }
