import { default as _91_46_46_46slug_93WrkYf26fM9Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexPvRzXsrrezMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addresses142bunGES3Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyJHRMEP99xeMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contact9JGErRL48oMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexAp5UBACj0mMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsii32PyfFyLMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_937oh0F9wJ7yMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersoNiOB5BtJlMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingdTaGnaQBsDMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45user5ZtGb0cQ3fMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editeaADdjdYvuMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexhC5a3SbxpBMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newjabgB0oS5OMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editqqj4TtWSTbMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as index3DCwISH28WMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileRtvrqtpLuYMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountANP2HanEOtMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93TIKByKPge9Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryKGMlTcWM6XMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cart9lOeQ5BhLzMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45payment9srUH1dxPtMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryINUo60hdNwMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutWAhSzuI6z1Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordMHB7RzSAUSMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordRRpcUUl1FOMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as index0IaA5A9nyWMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexFi365pLAWbMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as product35xmWXKn99Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93YVr33EKZI1Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexJ25ESxPIf8Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerOCQsC4DuavMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolvel6KgAzfx2zMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmshq4GqwZfH3Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storebKw5OY6jpiMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchjh3bSiBaiwMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionW2rdtqfmw7Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectiona8jOrGE0rIMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexSSonfFPXATMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientcvquiUYlYuMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementd48ZN5TNuzMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementpULfzBTN7TMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSection5RqvaPwbIIMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeader9LJ0TkOBIbMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionqKfWlkyyyjMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugGLhFTnbypXMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugForm1Ck4EUVH2ZMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalieEYQD9kiPMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyv8hh049DxVMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCta0ETsMhLmylMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftd2D12SjiRtMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightCeSnQtozJLMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationNIgywWmWhyMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSection0yPmdJd2bkMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidescGY8j7KsOlMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionYsELOj8rkEMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePagination8VSTRRzSRFMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightnEtIRe6t9jMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexBQkhoBt9YUMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantseTfJDc2se7Meta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageJEznOU4giPMeta } from "/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageoiK73KlFKDMeta } from "/home/ecomadm/app/releases/54/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93WrkYf26fM9Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/about-us/index.vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountANP2HanEOtMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addresses142bunGES3Meta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addresses142bunGES3Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyJHRMEP99xeMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyJHRMEP99xeMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contact9JGErRL48oMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contact9JGErRL48oMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsii32PyfFyLMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsii32PyfFyLMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_937oh0F9wJ7yMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: ordersoNiOB5BtJlMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersoNiOB5BtJlMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billingdTaGnaQBsDMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingdTaGnaQBsDMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45user5ZtGb0cQ3fMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editeaADdjdYvuMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexhC5a3SbxpBMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newjabgB0oS5OMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editqqj4TtWSTbMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: index3DCwISH28WMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: index3DCwISH28WMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profileRtvrqtpLuYMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileRtvrqtpLuYMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryKGMlTcWM6XMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93TIKByKPge9Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutWAhSzuI6z1Meta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutWAhSzuI6z1Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cart9lOeQ5BhLzMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cart9lOeQ5BhLzMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45payment9srUH1dxPtMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryINUo60hdNwMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordMHB7RzSAUSMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordRRpcUUl1FOMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: product35xmWXKn99Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93YVr33EKZI1Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue")
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexJ25ESxPIf8Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue")
  },
  {
    name: registerOCQsC4DuavMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerOCQsC4DuavMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolvel6KgAzfx2zMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchjh3bSiBaiwMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchjh3bSiBaiwMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cmshq4GqwZfH3Meta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmshq4GqwZfH3Meta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: storebKw5OY6jpiMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storebKw5OY6jpiMeta || {},
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/54/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/54/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]